import pavani from '../assets/pavani.jpg';
import prashanth from '../assets/prashanth.jpg';

import React, { useState, createContext } from 'react'

export const TrainerContext = createContext();

const TrainerContextProvider = (props) => {
    const trainer_data = [
        {
            id: 1,
            name: 'Pavani',
            gender: `female`,
            pic: pavani,
            type: 'nut',
            qualification: 'MS in Nutrition and Food Science from University of Georgia',
            experience: 3,
            slug: 'pavani'        
        },
        {
            id: 2,
            name: 'Prashanth',
            gender: `male`,
            pic: prashanth,
            type: 'gym',
            qualification: 'Certified Personal Fitness Trainer (CPFT)',
            experience: 12,
            slug:'prashanth'
        },
    ]

    const [trainers] = useState(trainer_data);

    return (
        <TrainerContext.Provider value={[...trainers]}>
            {props.children}
        </TrainerContext.Provider>
    )
}

export default TrainerContextProvider

