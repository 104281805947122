import React from 'react'

const ErrorPage = () => {
    return (
        <div>
           <h1>Error 404</h1> 
        </div>
    )
}

export default ErrorPage
