import React from 'react'

const Lazy = () => {
    return (
        <div className="lazy-load">
            <h1 className="heading__header--sub">
                <span className="header-logo-fit">Fit</span><span className="header-logo-iq">IQ</span><br/>
                <span className="header-tagline">Transform Yourself</span>
            </h1>
            <br/>
            <span className="lazy-loading">
                Loading... Please wait.
            </span>
            <span className="lazy-icon"></span>
        </div>
    )
}

export default Lazy
